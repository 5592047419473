import { useEffect, useState } from 'react'
import { SiWebauthn } from "react-icons/si";
import { useStateValue } from '../context/StateProvider';
import ControlPanel from './ControlPanel';

const Access = () => {
    const [{adminPass}, dispatch] = useStateValue()
    const [passCode, setPassCode] = useState('')
    const [flashNotification, setFlashNotification] = useState()
    const [cannotProcess, setCannotProcess] = useState(true)

    const authenticate = async () => {
        dispatch({ type: 'TOGGLE_LOADER'})
        setFlashNotification()
        try {
            if (passCode === adminPass)
                dispatch({type: 'SET_ADMIN', data: { timestamp: Date.now()}})
            else
                setFlashNotification(<div className='flash-note error'>Invalid Pass Code</div>)
        } catch(err) {

        }finally {
            dispatch({ type: 'TOGGLE_LOADER'})
        }
    }

    useEffect(() => {
        setFlashNotification('')
        setCannotProcess(true)
        if (!['', null].includes(passCode)) {
            let valueCharArray = passCode.split('')
            if (
                isNaN(valueCharArray[valueCharArray.length - 1])
            ) {
                const correctValue = valueCharArray.slice(0, valueCharArray.length - 1).join('')
                setPassCode(correctValue)
            }else if (valueCharArray.length === adminPass.length)
                setCannotProcess(false)
        }
    }, [passCode])
    return (
        <div className='content'>
            <div className="content-container small-centered-box">
                <div className="form-box">
                    <div className="input-box input-container">
                        <input 
                            type="text" 
                            name='passCode' 
                            id='passCode' 
                            value={passCode}
                            onChange={e => setPassCode(e.target.value)}/>
                        <label htmlFor="passCode">PASSCODE</label>
                    </div>
                    <div className="flash-notification">
                        {flashNotification !== null ? flashNotification : null}
                    </div>
                    <div className="btn-container">
                        <button
                            onClick={authenticate}
                            disabled={cannotProcess}
                            className={`btn ${cannotProcess ? 'disabled' : ''}`}>Authenticate <SiWebauthn /></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Access
