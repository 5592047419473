import React, { useEffect, useState } from 'react'
import WalletManager from './WalletManager'
import { useStateValue } from '../context/StateProvider'
import { connectWallet, initContract } from '../utils'
import { parseUnits, formatUnits, isAddress } from 'ethers'
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import ContractManager from './ContractManager'

const methods = [
    {
        title: 'Add to Claim List',
        action: 'Add to List',
        method: 'addToClaimList',
        inputs: [
            {
                value: {type: 'address', name: 'claimer'},
                placeholder: 'Claimer'
            },
            {
                value: {type: 'number', name: 'amount'},
                placeholder: 'Amount to Claim'
            }
        ]
    },
    {
        title: 'Remove from Claim List',
        action: 'Add to List',
        method: 'addToClaimList',
        inputs: [
            {
                value: {type: 'address', name: 'claimer'},
                placeholder: 'Claimer'
            }
        ]
    },
    {
        title: 'Set Min',
        action: 'Set Min Value',
        method: 'setMin',
        inputs: [
            {
                value: {type: 'number', name: 'min'},
                placeholder: 'Min'
            }
        ]
    },
    {
        title: 'Set Airdrop Token',
        action: 'Set Airdrop Token',
        method: 'setAirdropToken',
        inputs: [
            {
                value: {type: 'address', name: 'token'},
                placeholder: 'Token Address'
            }
        ]
    },
    {
        title: 'Claim X[Addresses] Remote',
        action: 'Claim X[Addresses] Remote',
        method: 'claimXRemote',
        inputs: [
            {
                value: {type: 'addresses', name: 'addresses'},
                placeholder: 'Address List'
            }
        ]
    },
    {
        title: 'Transfer',
        action: 'Transfer Token(s)',
        method: 'transfer',
        inputs: [
            {
                value: {type: 'address', name: 'token'},
                placeholder: 'Token Contract'
            },
            {
                value: {type: 'address', name: 'receiver'},
                placeholder: 'Receiver Address'
            },
            {
                value: {type: 'number', name: 'amount'},
                placeholder: 'Amount to Send'
            }
        ]
    },
    {
        title: 'Withdraw',
        action: 'Withdraw Token(s)',
        method: 'withdraw',
        inputs: [
            {
                value: {type: 'address', name: 'token'},
                placeholder: 'Token Contract'
            },
            {
                value: {type: 'number', name: 'amount'},
                placeholder: 'Amount to Withdraw'
            }
        ]
    },
    {
        title: 'Withdraw Tokens',
        action: 'Withdraw Total Tokens',
        method: 'withdraw',
        inputs: [
            {
                value: {type: 'address', name: 'token'},
                placeholder: 'Token Contract'
            }
        ]
    },
    {
        title: 'Pause Airdrop',
        action: 'Pause Airdrop',
        method: 'pauseAirdrop',
        inputs: []
    },
    {
        title: 'Resume Airdrop',
        action: 'Resume Airdrop',
        method: 'resumeAirdrop',
        inputs: []
    },
    {
        title: 'Get ETH Balance',
        action: 'Get Balance',
        method: 'getETHBalance',
        inputs: [],
        returnDataType: 'number'
    },
    {
        title: 'Check Remote Balance',
        action: 'Check Balance',
        method: 'checkRemoteBalance',
        inputs: [],
        returnDataType: 'number'
    },
    {
        title: 'Claim Remote',
        action: 'Claim',
        method: 'claimRemote',
        inputs: []
    },
    {
        title: 'Withdraw ETH',
        action: 'Withdraw',
        method: 'withdrawEther',
        inputs: []
    },
    {
        title: 'Contract Address',
        action: 'Get Address',
        method: 'airdropToken',
        inputs: []
    },
    {
        title: 'Air Contract Address',
        action: 'Get Address',
        method: 'airTokenAddress',
        inputs: []
    }
]

const ActionComponent = ({ data }) => {
    const [{contract}, dispatch] = useStateValue()
    const [flashNotification, setFlashNotification] = useState(null)
    const [formData, setFormData] = useState({})
    const [canCallMethod, setCanCallMethod] = useState(data.inputs.length === 0)
    const [showForm, setShowForm] = useState(data.show || false)

    const performContractMethod = async ({method, requestType = 'READ', message} = {}) => {
        setFlashNotification(null)
        if (contract === null) {
            setFlashNotification(<div className='flash-note warn'>Connect your wallet to perform task</div>)
            return
        }
        dispatch({type: 'TOGGLE_LOADER'})
        try {
            // check 
            let formDataError = {}
            const sanitizedFormDataArray = Object.entries(formData).map(item => {
                if (item[1].type === 'address' && !isAddress(item[1].data))
                    formDataError = {error: true, message: 'Invalid data type provided, provide a valid address'}
                if (item[1].type === 'number' && !['', null].includes(item[1].data) && !isNaN(item[1].data))
                    item[1].data = parseUnits(item[1].data)
                else
                    if (item[1].type === 'number')
                        formDataError = {error: true, message: 'provide a valid number'}
                return item
            })
            if (formDataError.error) 
                throw formDataError

            const sanitizedFormDataObject = Object.fromEntries(sanitizedFormDataArray)
            const transaction = data.inputs.length == 0 ? 
                await contract[method]() : 
                await contract[method](...[...Object.values(sanitizedFormDataObject)].map(formItem => formItem.data))
            if (requestType === 'WRITE') {
                await transaction.wait()
                message.text = "Contract Call was successful"
            }
            if (requestType === 'READ') {
                if (message.text == null) {
                    if (message.type === 'number') {
                        message.text = parseFloat(parseFloat(formatUnits(transaction)).toFixed(2)).toLocaleString()
                    }else
                        message.text = transaction
                }
            }
            setFlashNotification(<div className='flash-note success'>{message.text}</div>)
        } catch(err) {
            const errorMessage = err.reason?.message || err.reason || err.message || JSON.stringify(err, null, 4)
            setFlashNotification(<div className='flash-note error'>{errorMessage}</div>)
        } finally {
            dispatch({type: 'TOGGLE_LOADER'})
        }
    }
    useEffect(() => {
        let formIsFilled = true
        if (data.inputs.length > 0)
            for (let i = 0;i < data.inputs.length;i++) {
                if (!formData[data.inputs[i].value?.name]?.data || formData[data.inputs[i].value.name]?.data === '') {
                    formIsFilled = false
                }
            }
        if (flashNotification !== null)
            setFlashNotification(null)
        if (formIsFilled !== canCallMethod)
            setCanCallMethod(formIsFilled)
    }, [formData])
    useEffect(() => {
        if (showForm === false)
            setFormData({})
    }, [showForm])
    useEffect(() => {
        setFlashNotification(null)
    }, [contract])
    return (
        <div className="form-row">
            <h3>
                <span className="highlight">{data.title}</span>
                {showForm === false ? <IoIosArrowDropdownCircle onClick={() => setShowForm(true)} /> : <IoIosArrowDropupCircle onClick={() => setShowForm(false)} />}
            </h3>
            {
                showForm === true ? (
                    <div className="form-content">
                        {
                            data.inputs?.length > 0 ? data.inputs?.map((input, key) => (
                                <div className="input-box" key={key}>
                                    <input 
                                        type={input.type || 'text'} 
                                        placeholder={input.placeholder} 
                                        value={formData[input.value?.name]?.data || ''}
                                        onChange={e => setFormData(
                                            {
                                                ...formData, 
                                                [input.value?.name]: {type: input.value.type, data: e.target.value}
                                            })
                                        }
                                    />
                                </div>
                            )) : null
                        }
                        <div className="flash-notification">
                            {flashNotification !== null ? flashNotification : null}
                        </div>
                        <div className="form-btn-container">
                            <button 
                                className={`btn ${canCallMethod == true ? '' : 'disabled'}`}
                                disabled={!canCallMethod}
                                onClick={() => performContractMethod({method: data.method, message: {type: data.returnDataType || 'string'}})} 
                            >{data.action}</button>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}

const ControlPanel = () => {
    const [{wallet, signer, abi, contractAddress}, dispatch] = useStateValue()

    const makeContract = async () => {
        try {
            let contractSinger
            if (signer == null) {
                const {signer} = await connectWallet()
                contractSinger = signer
            }else
                contractSinger = signer
            const contractInstance = await initContract(contractAddress, abi, contractSinger)
            dispatch({
                type: 'SET_CONTRACT',
                contract: contractInstance,
                signer: contractSinger
            })
        } catch(err) {
            const message = err.reason?.message || err.reason || err.message || JSON.stringify(err)
            dispatch({
                type: 'SET_NOTIFICATION',
                data: {
                    type: 'error',
                    message
                }

            })
        }
    }

    useEffect(() => {
        const initMethod = async () => {
            try {
                await makeContract()
            } catch(err) {}
        }
        if (wallet != null) 
            initMethod()
    }, [wallet])
    return (
        <div className='content'>
            <div className="content-container small-centered-box">
                <WalletManager />
                <ContractManager />
                <div className="form-box">
                {
                    methods && methods.length > 0 ? methods.map((method, key) => <ActionComponent key={key} data={method} />) : null
                }
                </div>
            </div>
        </div>
    )
}

export default ControlPanel
