
import { getWindowDimensions, getStorage, setStorage, removeStored } from './general'
import abi from '../utils/abi.json'
import tokenAbi from '../utils/tokenAbi.json'

export const initialState = {
    abi,
    tokenAbi,
    contract: null,
    provider: null,
    tokenContract: null,
    signer: null,
    admin: getStorage('session-storage', 'admin') ? JSON.parse(getStorage('session-storage', 'admin')) : null,
    adminPass: '120616032090',
    contractAddress: 
        getStorage('cookies', 'contract') ? 
        getStorage('cookies', 'contract') : 
        '0x5EC4892fE584D2985581a2A327ae9F52756f8264',
    airDropToken: '0x420FcA0121DC28039145009570975747295f2329',
    networkTitle: 'Avalanche C-Chain',
    allowedChainIds: [43113n, 43114n],
    minTokenBalanceRequired: '24 Million',
    tokenName: 'COQ Inu Token',
    networkSymbol: 'AVAX',
    airTokenName: 'COQ Inu Air Token',
    activeTheme: getStorage('cookies', 'theme') ? getStorage('cookies', 'theme') : "dark",
    windowDimensions: getWindowDimensions(),
    wallet: getStorage('session-storage', 'wallet') ? getStorage('session-storage', 'wallet') : null,
    banner: {
        exp: '2024/01/02 00:00:00',
        message: 'Just a dummy text'
    },
    showHeader: true,
    showFooter: true,
    colors: {
        primary: '#000b26',
        secondary: '#6a2ef0',
        dark: '#1e272a',
        darker: '#12181a',
        lighter: '#2a373b',
        light: '#f2f2f2',
        green: '#228b22'
    },
    showFlashNotification: null,
    showWalletOptions: null,
    content: null,
    notification: null,
    loading: null,
    modal: null,
    balance: 0
}

const reducer = (state, action)=>{
    switch (action.type) {
        case 'SET_THEME':
            setStorage('cookies', 'theme', state.activeTheme === 'dark' ? 'light' : 'dark')
            return {
                ...state,
                activeTheme: state.activeTheme === 'dark' ? 'light' : 'dark'
            }
        case 'SET_ADMIN':
            setStorage('session-storage', 'admin', JSON.stringify(action.data))
            return {
                ...state,
                admin: action.data
            }
        case 'SET_WALLET':
            setStorage('session-storage', 'wallet', action.address)
            return {
                ...state,
                wallet: action.address
            }
        case 'CLEAR_WALLET':
            removeStored('session-storage', 'wallet')
            return {
                ...state,
                wallet: null
            }
        case 'TOGGLE_SHOW_WALLET_OPTIONS':
            return {
                ...state,
                showWalletOptions: action.option
            }
        case 'TOGGLE_CONTENT':
            return {
                ...state,
                content: action.content,
                balance: action.balance || 0
            }
        case 'SET_DIMENSIONS':
            return {
                ...state,
                windowDimensions: getWindowDimensions()
            }
        case 'TOGGLE_HEADER':
            return {
                ...state,
                showHeader: action.choice
            }
        case 'TOGGLE_FOOTER':
            return {
                ...state,
                showFooter: action.choice
            }
        case 'TOGGLE_LOADER':
            return {
                ...state,
                loading: state.loading != null ? null : true
            }
        case 'SET_NOTIFICATION':
            return {
                ...state,
                showFlashNotification: action.data
            }
        case 'CLEAR_NOTIFICATION':
            return {
                ...state,
                showFlashNotification: null
            }
        case 'SET_MODAL':
            return {
                ...state,
                modal: action.data
            }
        case 'SET_CONTRACT':
            return {
                ...state,
                provider: action.provider || state.signer || null,
                contract: action.contract || state.signer || null,
                tokenContract: action.tokenContract || state.tokenContract || null,
                signer: action.signer || state.signer || null
            }
        case 'SET_CONTRACT_ADDRESS':
            setStorage('cookies', 'contract', action.contract)
            return {
                ...state,
                contractAddress: action.contract || state.contractAddress || '0x5EC4892fE584D2985581a2A327ae9F52756f8264'
            }
            
        
        default:
            return state;
    }
}

export default reducer;