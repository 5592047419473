import React, { useState } from 'react'
import { useStateValue } from '../context/StateProvider';

import avax from '../media/images/avax.png'
import bsc from '../media/images/bsc.png'

const contracts = [
    {contract: "0x5EC4892fE584D2985581a2A327ae9F52756f8264", title: "COQ Inu V1", icon: avax},
    {contract: "0xaA2CA395Eb7B3726B578a80514c457a30123Bc98", title: "COQ Inu V2", icon: avax},
    {contract: "0x35022A5972Bca19bBb8663be095edc921Ca9E1d2", title: "Pancakeswap V1", icon: bsc}
]

const ContractManager = () => {
    const [{contractAddress}, dispatch] = useStateValue()
    const [showContractSelection, setShowContractSelection] = useState(false)

    const setContract = (contract) => {
        dispatch({
            type: 'SET_CONTRACT_ADDRESS',
            contract
        })
        setTimeout(() => window.location.reload(), 100)
    }
    return (
        showContractSelection ? (
            <div className="modal-container">
                <div className="modal-box">
                    <ul>
                        {contracts && contracts.length > 0 ? 
                            contracts.map(({contract, title, icon}, index) => 
                                <li
                                    key={index}
                                    className='network-item'
                                    onClick={() => setContract(contract)}>
                                    <div className="title">{title}</div>
                                    <div className="address">
                                        <span className="highlight">{contract.slice(0, 5)}</span>
                                        <span className="highlight">****</span>
                                        <span className="highlight">{contract.slice(contract.length - 5, contract.length)}</span>
                                    </div>
                                    <div className="icon">
                                        <img src={icon} alt="Network Icon" height={30} />
                                    </div>
                                </li>
                            ) : 
                            null
                        }
                    </ul>
                </div>
            </div>
        ) : (<button 
                onClick={() => setShowContractSelection(!showContractSelection)} 
                className='btn'>
                    {contracts.filter(({contract}) => contract === contractAddress)[0].title}
            </button>
        )
    )
}

export default ContractManager
