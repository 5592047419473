import React, { useState, useEffect } from 'react'
import { useStateValue } from '../context/StateProvider'
import { connectWallet } from '../utils'

import { IoWalletOutline } from "react-icons/io5";
import { PiPlugsConnectedDuotone } from "react-icons/pi";

const WalletManager = ({btnText = null}) => {
    const [{ wallet, showWalletOptions }, dispatch] = useStateValue()
    const [textContent, setTextContent] = useState('')
    const [btnClass, setBtnClass] = useState('btn disconnected')

    async function manageWallet() {
        if (wallet === null) {
            try {
                const {address, signer, provider} = await connectWallet()
                dispatch({
                    type: 'SET_WALLET',
                    address
                })
                dispatch({
                    type: 'SET_CONTRACT',
                    signer,
                    provider
                })
                dispatch({
                    type: 'SET_NOTIFICATION',
                    data: {
                        type: 'success',
                        message: 'Connected!'
                    }
                })
            } catch(err) {
                console.error(err)
                dispatch({
                    type: 'SET_NOTIFICATION',
                    data: {
                        type: 'error',
                        message: err.message
                    }
                })
            }
        }else if (showWalletOptions === null) {
            dispatch({
                type: 'TOGGLE_SHOW_WALLET_OPTIONS',
                option: true
            })
        }
    }
    function strippedAddress(address) {
        return `${address.slice(0, 5)}....${address.slice(address.length - 4, address.length)}`
    }

    useEffect(() => {
        if (wallet != null) {
            setTextContent(strippedAddress(wallet))
            setBtnClass('btn connected')
        }
        else {
            setTextContent('Connect Wallet')
            setBtnClass('btn disconnected')
        }
    }, [wallet])
    useEffect(() => {
        if (btnText !== null)
            setTextContent(btnText);
    }, [btnText])
    return (
        <button 
            className={btnClass}
            onClick={manageWallet}>
            {wallet === null ? <IoWalletOutline /> : <PiPlugsConnectedDuotone className="connect-icon" /> }{textContent}
        </button>
    )
}

export default WalletManager
