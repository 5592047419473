import React, { useState, useEffect } from 'react'
import { useStateValue } from '../context/StateProvider';
import ControlPanel from './ControlPanel';
import Access from './Access';

const Content = () => {
    const [{ admin, content }] = useStateValue();
    const [contentDisplay, setContentDisplay] = useState(<ControlPanel />);

    useEffect(() => {
        if (content != null)
            setContentDisplay(content)
    }, [content])
    return (
        admin != null ? contentDisplay : (<Access />)
    )
}

export default Content
